@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Amita:wght@400;700&display=swap');

/* font-family: "Amita", serif;
  font-weight: 400;
  font-style: normal; */

 
*{
  margin: 0px;
  padding: 0px;
  /* background-color: white; */
}

::selection {
  color: white;
  background: lightcoral;
}

.loader-container{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

}

html {
  scroll-behavior: smooth;
}

.gototop_container{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.top_btn{
font-size: 2.4rem;
width: 4rem;
height: 4rem;
color: #fff;
background-color: #F08080;
border-radius: 50%;
position: fixed;
bottom: 2rem;
right: 2rem;
z-index: 999;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
}

.top_btn img{
  width: 100%;
  color: white;
  animation: gototop 1.2s linear infinite alternate-reverse;

}

  @keyframes gototop {
  0% {
  transform: translateY(-0.8rem);
  }
  100% {
  transform: translateY(0.3rem);
  }
  }