.home_container {
  width: 100%;
  height: 100vh;
  display: flex;
  box-sizing: border-box;
}

.top_nav_bar{
  width: 100%;
  height: 50px;
  display: flex;
  display: none;

  justify-content:space-around;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}
.top_nav_bar li{
  padding: 5px 30px;
  border-radius: 5px;
  font-size: 18px;
  background-color:#F08080  ;
  cursor: pointer;
  color: white ;
  font-family: "Poppins", sans-serif;
}

.top_nav_bar li:active{
  padding: 4px 25px;
  background-color:rgb(145, 217, 241)  ;
  
}

.top_nav_bar li > a{
  text-decoration: none;
  color: white;
}

.top_left {
  width: 65%;
  height: 100vh;
  display: flex;
  justify-content: center;
  text-align: center;
}
.top_right {
  width: 35%;
  height: 100vh;
}
.top_right_img{
    width: 100%;
    height: 100%;
    object-position: center;
}

.hero_container{
 width: 100%;
 justify-content: center;
 align-items: center;
 text-align: center;

}

.hero_text_1{
  margin-top: 2%;
  margin-bottom: 3vh;
  text-transform:uppercase;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1.5px;
  font-size: 20px;
  color: white;
  font-weight: 400;
  background-color: lightcoral;
}

/* second div styles */
.hero_text_2{
  width: 100%;
  height: 50vh;
}
.hero_text_2_1{
  font-size: 130px;
  font-family: "Dancing Script", cursive;
}
.hero_text_2_2{
  font-size: 150px;
  font-family: "Dancing Script", cursive;
}
/* third div styles */

.hero_text_3{
  width: 100%;
  margin-top: 15%;
  display: flex;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  justify-content: space-between;
  text-transform: capitalize;
}
.hero_text_3_1{
  margin-left: 8%;
}

.hero_text_3_1 p{
  font-size: 18px;
  font-weight: 500;
}

.hero_text_3_2 p {
  font-size: 18px;
  font-weight: 500;
}

.hero_text_3_2{
  margin-right: 8%;
  cursor: pointer;
}

/* hr design */

hr{
  border-top: 1.5px solid lightcoral;
  width: 100%;
  font-size: 5px;
}

@media screen and (max-width:750px) {
  .top_nav_bar{
    width: 100%;
    display: flex;
    height: 50px;
  }
  .top_nav_bar li{
    padding: 3px 10px;
    border-radius: 5px;
    font-size: 11px;
    background-color:#F08080  ;
    cursor: pointer;
    color: white ;
    font-family: "Poppins", sans-serif;
  }
  
  .top_nav_bar li:active{
    padding: 2px 8px;
    background-color:rgb(145, 217, 241)  ;
    
  }
}

@media screen and (max-width: 600px){
  .top_right {
    display: none;
  }
  .top_left {
    width: 100%;
  }
}