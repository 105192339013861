

.about_container {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background-color: rgba(113, 133, 127, 0.116);
}
.top_about_Text {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top_about_Text h1 {
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 40px;
}
.center_about_text {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  height: auto;
  flex-wrap: wrap;
  justify-content: space-around;
}
.center_about_text div {
  flex: 1 300px;
}
.grid_layout {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 10px;
  row-gap: 35px;
  padding-left: 100px;
  padding-right: 20px;
  align-items: center;
  text-align: center;
}

.grid_layout div {
  height: 200px;
  width: 250px;
  background-color: rgba(240, 128, 128, 0.219);
  font-size: 30px;
  display: flex;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  line-height: 40px;
  letter-spacing: .5px;
}
.grid_layout div p:nth-child(1){
  color: lightcoral;
  font-size: 45px;
  font-weight: 700;
}
.grid_layout div p:nth-child(2){
  font-size: 17px;
}
.about_me{
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}
.about_me h5{
   text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  color: lightcoral;
  
}
.about_me h1{
  font-size: 45px;
  margin-top: 5px;
  font-weight: 600;
  line-height: 60px;
}
.about_me p {
    color: rgb(136, 134, 134);
    letter-spacing: .5px;
    margin-top: 35px;
}

.about_me table{
  margin-top: 40px;
}
.about_me table tr td:nth-child(even){
  color: lightcoral;
}

.about_me table tr td{
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  /* width: 50%; */
  height: 35px;
  letter-spacing: .5px;
}
.down_cv{
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-bottom: 50px; */
  
}

/* CSS */
.btn {
  all: unset;
  width: 120px;
  font-family: "Poppins", sans-serif;
  height: 30px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btn::after,
.btn::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all .4s;
}

.btn::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: lightcoral;
  border-radius: 10px;
}

.btn::after {
  transform: translate(10px, 10px);
  width: 45px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}

.btn:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.btn:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.btn:active::after {
  transition: 0s;
  transform: translate(0, 5%);
  /* background:crimson; */

}

.btn:active::before {
  transition: 0s;
  transform: translate(0, 5%);
  background:crimson;

}




@media screen and (max-width: 1200px) {
 
}
@media screen and (max-width: 750px) {
  .top_about_Text {
    height: 30vh;
  }
  .grid_layout div {
    height: 180px;
    width: 180px;
    background-color: rgba(240, 128, 128, 0.219);
    font-size: 30px;
    display: flex;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    line-height: 40px;
    letter-spacing: .5px;
  }
  .about_me{
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
    padding: 10px;
  }
  .grid_layout {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 30px;
    row-gap: 30px;
    padding-left: 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    padding-right: 0px;
    align-items: center;
    text-align: center;
  }
 
}
@media screen and (max-width: 480px) {
  .top_about_Text {
    height: 40vh;
  }
  .top_about_Text h1 {
    font-weight: 300;
  }
  .grid_layout {
    grid-template-columns:1fr;
    padding-left: 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: 0px;
  }
  .grid_layout div {
    border-radius: 10px;  
      font-size: 40px;
    height: 150px;
    width: 250px;
  }
  .about_me{
    margin-top: 50px;
    margin-left: 0px;
    padding: 10px 20px;
    /* margin-right: 50px; */
  }
  .about_me h1{
    font-size: 25px;
    margin-top: 10px;
    font-weight: 600;
    line-height: 35px;
  }
  .about_me p {
      color: rgb(136, 134, 134);
      letter-spacing: .5px;
      font-size: 18px;
      margin-top: 35px;
  }
  .about_me table{
   display: none;
  }
}




