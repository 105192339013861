

.test_container{
    width: 100%;
    height: auto;
    padding-bottom:50px ;
    box-sizing: border-box;
    background-color: rgba(235, 191, 97, 0.219);
}

.top_test_text{
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Poppins", sans-serif;

}

.top_test_text h1 {
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 40px;
}

.top_test_text p{
   color: lightcoral;
   font-size: 15px;
   font-weight: 600;
   letter-spacing: .5px;
}


*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

:root {
  --primary: #6a59ff;
  --white: #ffffff;
  --bg: #f5f5f5;
}

html {
  font-size: 60%;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 1.3rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #797979;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

.container {
  width: 100%;
  padding: 0px 350px;
}

.swiper_container {
  height: 48rem;
  padding: 2rem 0;
  position: relative;
}

.swiper-slide {
  width: 40rem;
  height: 30rem;
  position: relative;
}



/* .swiper-slide img {
  width: 37rem;
  height: 42rem;
  border-radius: 2rem;
  object-fit: cover;
} */

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

.slider-controler .slider-arrow {
  background: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: '';
}

.swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}




.card{
  width: 340px;
  background-color: white;
  font-family: "Poppins", sans-serif;
  height: 380px;
  border-radius: 20px;
  padding: 20px;
}

.card_div_1{
  width: 100%;
  display: flex;
  height:17vh;
  margin-bottom: 15px;
}

.card_div_1 div:nth-child(1){
  width: 38%;
  /* height: 100%; */
}
.card_div_1 div:nth-child(2){
  width: 62%;
  display: flex;
  flex-direction:column ;
  justify-content: center;
  padding-left: 15px;


}

.card_div_1 div:nth-child(1) img{
  width: 100px ;
  height: 100px;
  object-fit: cover;
  object-position:left top;
  overflow: hidden;
  border-radius: 50%;
}




.card_div_1 div:nth-child(2) h2{
 font-size: 25px;
 padding-bottom: 3px;

}

.card_div_1 div:nth-child(2) p{
  color: lightcoral;
  font-size: 14px;
  letter-spacing: .5px;
  font-weight: 500;
}
.card_div_2{
  width: 100%;
  height: 35vh;
  overflow: hidden;
}

.card_div_2 p{
  color: #797979;
  font-size: 17px;
  margin-left: 3px;
}

@media screen and (max-width: 750px) {
  .test_container{
    padding-top: 450px;
  }
  .container {
    width: 100%;
    padding: 0px 20px;
  }
  .card{
    width: 270px;
    border-radius: 10px;
    padding: 20px;
  }
  .card_div_1 div:nth-child(2){
    padding-left: 25px;
  }
  
  .card_div_1 div:nth-child(2) h2{
   font-size: 17px;
  }
  
  .card_div_1 div:nth-child(2) p{
    font-size: 12px;
    letter-spacing: .5px;
    font-weight: 500;
  }
  .card_div_2{
    width: 100%;
    height: 35vh;
    overflow: hidden;
  }
  
  
  .card_div_2 p{
    color: #797979;
    font-size: 17px;
    margin-left: 3px;
    overflow: hidden;
  text-overflow: ellipsis; 
  }
  

}


@media screen and (max-width: 480px) {
  .test_container{
    padding-top: 40vh;
  }
  .container {
    width: 100%;
    padding: 0px 20px;
  }
  .card{
    width: 270px;
    border-radius: 10px;
    padding: 20px;
  }
  .card_div_1 div:nth-child(2){
    padding-left: 25px;
  }
  
  .card_div_1 div:nth-child(2) h2{
   font-size: 17px;
  }
  
  .card_div_1 div:nth-child(2) p{
    font-size: 12px;
    letter-spacing: .5px;
    font-weight: 500;
  }
  .card_div_2{
    width: 100%;
    height: 35vh;
    overflow: hidden;
  }
  
  
  .card_div_2 p{
    color: #797979;
    font-size: 17px;
    margin-left: 3px;
    overflow: hidden;
  text-overflow: ellipsis; 
  }
  
  
}